import React from 'react';
import './App.css';
import '@shopify/polaris/dist/styles.css';
import esTranslations from '@shopify/polaris/locales/es.json';
import {AppProvider, Page, Card, Button, Navigation, TextField, Heading, Subheading, DisplayText, Spinner, TextContainer, Stack, Collapsible, Link, DataTable} from '@shopify/polaris';
import {HomeMajor, OrdersMajor, ProductsMajor} from '@shopify/polaris-icons';


interface AppProps{}
interface AppState{
    apiKey: string;
    loading: boolean;
    error: boolean;
    success: boolean;
    genericError:boolean;
    synchedAccounts: any[];
}
class App extends React.Component<AppProps, AppState> { 

  constructor(props: any){
    super(props);
    this.state = {
      apiKey: "",
      loading: false,
      error: false,
      success: false,
      genericError: false, 
      synchedAccounts: []
    }
    this.handleApiKeyChange = this.handleApiKeyChange.bind(this);
    this.saveApiKey         = this.saveApiKey.bind(this);
  }

  componentDidMount(){
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('token');
    this.getSynchedAccounts();
    if(myParam == null){
        this.setState({
          genericError: true
        });
    }else{
      localStorage.setItem("token", myParam);
    }
  }

  public handleApiKeyChange($event: any, $id: any){
      this.setState({
          apiKey: $event
      });
  }

  public getSynchedAccounts(){
    const url = "https://api.multistorefy.appsbealion.com/api/shop/authorized-accounts";
    fetch(url, {
      method: "GET" , 
      headers: {
        'X-Token': localStorage.getItem("token") ?? ""
      }
    }).then(async (response: Response) =>{
      if(response.status !== 200){
        
      }else{
        this.setState({
          synchedAccounts: (await response.json())
        })
      }
    });

  }

  public saveApiKey(){
      const url = "https://api.multistorefy.appsbealion.com/api/shop/authorize-shop";
      const formData = new FormData();
      formData.append("api_key" , this.state.apiKey);
      this.setState({
        loading: true
      });
      fetch(url, {
        method: "POST", 
        body: formData, 
        headers: {
          'X-Token': localStorage.getItem("token") ?? ""
      }
      }).then((response: Response) =>{
        if(response.status !== 200){
          this.setState({
            success: false,
            error: true
          });
        }else{
          this.setState({
            success: true,
            error: false
          })
        }
        this.setState({
          loading: false
        })
      });
    
  }


  
  public get firstStep() : any {
    return (
      <div style={{margin: "8px"}}>
      <Card sectioned>
        <Stack vertical>
        <TextContainer>
            <Heading>
              1 - Ve a <a target="_blank" href="https://multistorefy.appsbealion.com/register">multistorefy</a>
            </Heading>
              <p>
                Registrate en <a target="_blank" href="https://multistorefy.appsbealion.com/register">multistorefy</a> e inicia sesión.
              </p>              
            </TextContainer>
        </Stack>
      </Card>
    </div>
    )
  }

  public get secondStep() : any {
    return (
      <div style={{margin: "8px"}}>
      <Card sectioned>
        <Stack vertical>
        <TextContainer>
            <Heading>
              2 - Copia tu clave de vinculación
            </Heading>
              <p>
                Accede a "Tiendas" y copia la clave que aparece.
              </p>              
            </TextContainer>
        </Stack>
      </Card>
    </div>
    )
  }

  
  public get thirdStep() : any {
    return (
      <div style={{margin: "8px"}}>
      <Card sectioned>
        <Stack vertical>
        <TextContainer>
            <Heading>
              3 - Pega la clave y guarda
            </Heading>
            <TextField value={this.state.apiKey} label="Pega aqui la clave" onChange={this.handleApiKeyChange} />        
          <Button onClick={this.saveApiKey}>Guardar</Button>          
            </TextContainer>
        </Stack>
      </Card>
    </div>
    )
  }
  
  public get fourthStep() : any {
    return (
      <div style={{margin: "8px"}}>
      <Card sectioned>
        <Stack vertical>
        <TextContainer>
            <Heading>
              4 - Ve de nuevo a "Tiendas" en <a target="_blank" href="https://multistorefy.appsbealion.com/register">multistorefy</a>
            </Heading>
              <p>
                Desde el panel de control de multistorefy, ve a "Tiendas" y confirma tu tienda para gestionar los pedidos en multistorefy.
              </p>                            
            </TextContainer>
        </Stack>
      </Card>
    </div>
    )
  }  


  
  public app(spinner: any, error: any, success: any) : any {
    return (
      
      <AppProvider i18n={esTranslations}>
      <div className="App">
        <div>
          <div>
            {this.firstStep}
            {this.secondStep}
            {this.thirdStep}
            {this.fourthStep}
            {this.synchedAccounts}
        </div>    
          {spinner}
          {error}
          {success}
        </div>
      </div>
      </AppProvider>
    );
  }


  public deatachAccount(id: string){
    alert(id);
  }
  
  public get rows() : any[] {
    return this.state.synchedAccounts.map((e) => [
      e["email"] , 
      e["api_key"], 
      <Button onClick={() => {this.deatachAccount(e["id"])}} primary={true}>Desvincular</Button>
    ]);
  }
  

  public get synchedAccounts(): any{
    return (
      <Card>
        <DataTable columnContentTypes={[
          'text', 'text', 'text'
        ]}
        headings={[
          "Email", 
          "Api key", 
          "Acciones"
        ]}
        rows={this.rows} />
      </Card>
    )
  }
  


  render() { 
    var spinner    = this.state.loading === true ? <Spinner accessibilityLabel="Spinner example" size="large" /> : <div></div>
    var error      = this.state.error === true ? <div style={{color: "red"}}>Clave invalida</div> : <div></div>;
    var success    = this.state.success === true ? <div style={{color: "green"}}>Tienda vinculada correctamente</div> : <div></div>;
    let rendered   = this.state.genericError === true ? <h1>Something wrong.</h1> : this.app(spinner, error, success);
    return rendered;
  }
} 

export default App;
